@import "./variables.css";

.condensed-600 {
  font-family: Dosis, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 600;
}

/* ========== LAYOUT ========== */
.max-width-0_5 { max-width: 12rem; }
.max-height-100 { max-height: 100vh; }
.max-height-33 { max-height: 33.33333vh; }
.min-height-200 { min-height: 200vh; }
.min-height-100 { min-height: 100vh; }
.min-height-75 { min-height: 75vh; }
.min-height-50 { min-height: 50vh; }
.min-height-25 { min-height: 25vh; }
.min-height-0 { min-height: 0; }
.min-width-0 { min-width: 0; }
.min-height-auto { min-height: auto; }
.height-25 { height: 25%; }
.height-33 { height: 33.33333%; }
.height-50 { height: 50%; }
.height-75 { height: 75%; }
.height-100 { height: 100%; }
.height-auto { height: auto; }
.height-inherit { height: inherit; }
.width-20 { width: 20%; }
.width-auto { width: auto; }
.max-width-700 { max-width: 700px; }
.overflow-visible { overflow: visible; }
.overflow-y-auto { overflow-y: auto; }
.overflow-y-scroll { overflow-y: scroll; }
.visibility-hidden { visibility: hidden; }

.inline-flex { display: inline-flex; }
.flex-row { flex-direction: row; }
.row-reverse { flex-direction: row-reverse; }
.flex-grow { flex-grow: 1; }
.static { position: static; }
.sticky { position: sticky; }

/*
  recruit-margin is used to mimic responsive margins of tab contents within recruit profiles
  these margin values come directly from the md-card margin styles
 */
.recruit-margin { margin: 16px 8px 0 8px; }
@media (max-width: 900px) {
  .recruit-margin { margin: 0 0 8px 0; }
}

.pos-center {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.posx-center {
  left: 50%;
  transform: translateX(-50%);
}

.posy-center {
  top: 50%;
  transform: translateY(-50%);
}

.left-25 { left: 25%; }
.left-33 { left: 33.33333%; }
.left-50 { left: 50%; }
.left-67 { left: 66.66667%; }
.left-75 { left: 75%; }
.right-25 { right: 25%; }
.right-33 { right: 33.33333%; }
.right-50 { right: 50%; }
.right-67 { right: 66.66667%; }
.right-75 { right: 75%; }
.top-100 { top: 100%; }
.bottom-100 { bottom: 100%; }

.table-fixed { table-layout: fixed; }

@media (min-width: 1200px) {
  .lg-left-25 { left: 25%; }
  .lg-left-33 { left: 33.33333%; }
  .lg-left-50 { left: 50%; }
  .lg-left-67 { left: 66.66667%; }
  .lg-left-75 { left: 75%; }
  .lg-right-25 { right: 25%; }
  .lg-right-33 { right: 33.33333%; }
  .lg-right-50 { right: 50%; }
  .lg-right-67 { right: 66.66667%; }
  .lg-right-75 { right: 75%; }
}

/* classes for positioning fixed tab containers */
.tap-pane-left-0 {
  left: 0;
}
.tap-pane-left-25 { left: 0; }
.tap-pane-left-50 { left: 0; }
@media (min-width: 900px) {
  .tap-pane-left-0 { left: 57px; }
  .tap-pane-left-25 { left: calc(57px + (100% - 57px) * 0.25); }
  .tap-pane-left-50 { left: calc(57px + (100% - 57px) * 0.5); }
}
@media (min-width: 900px) {
  .tap-pane-col-3 { width: calc((100% - 57px) * 0.25); }
  .tap-pane-col-6 { width: calc((100% - 57px) * 0.5); }
  .tap-pane-col-9 { width: calc((100% - 57px) * 0.75); }
}

@supports (-webkit-overflow-scrolling: touch) {
  .ios-scrolling {
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll;
  }
}

/* ========== BORDER ========== */

.bt1 {
  border-top-style:solid;
  border-top-width: 0.5rem;
}

.border-w2 { border-width: 2px; }
.border-w4 { border-width: 4px; }
.border-w10 { border-width: 10px; }
.circle-top-left { border-top-left-radius: 100px; }
.circle-bottom-left { border-bottom-left-radius: 100px; }
.circle-top-right { border-top-right-radius: 100px; }
.circle-bottom-right { border-bottom-right-radius: 100px; }

.border-dashed { border-style: dashed; }
.bx0 {
  border-left: none;
  border-right: none;
}
.by1 {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

@media (min-width: 900px) {
  .md-border {
    border-style: solid;
    border-width: 1px;
  }

  .md-border-top {
    border-top-style: solid;
    border-top-width: 1px;
  }

  .md-border-right {
    border-right-style: solid;
    border-right-width: 1px;
  }

  .md-border-bottom {
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }

  .md-border-left {
    border-left-style: solid;
    border-left-width: 1px;
  }

  .md-border-none { border: 0; }
}

@media (min-width: 1200px) {
  .lg-border {
    border-style: solid;
    border-width: 1px;
  }

  .lg-border-top {
    border-top-style: solid;
    border-top-width: 1px;
  }

  .lg-border-right {
    border-right-style: solid;
    border-right-width: 1px;
  }

  .lg-border-bottom {
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }

  .lg-border-left {
    border-left-style: solid;
    border-left-width: 1px;
  }

  .lg-border-none { border: 0; }
}

@media (min-width: 1600px) {
  .xl-border {
    border-style: solid;
    border-width: 1px;
  }

  .xl-border-top {
    border-top-style: solid;
    border-top-width: 1px;
  }

  .xl-border-right {
    border-right-style: solid;
    border-right-width: 1px;
  }

  .xl-border-bottom {
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }

  .xl-border-left {
    border-left-style: solid;
    border-left-width: 1px;
  }

  .xl-border-none { border: 0; }
}

/* ========== TYPOGRAPHY ========== */

.line-height-1 { line-height: 1; }
.line-height-2 { line-height: 1.25; }
.line-height-1_3 { line-height: 1.3; }
.line-height-5 { line-height: 1.75; }
.line-height-initial { line-height: initial; }
.line-height-inherit { line-height: inherit; }
.line-through { text-decoration: line-through; }
.no-resize { resize: none; }
.pre-wrap { white-space: pre-wrap; }
.pre-line { white-space: pre-line; }
.text-transform-none { text-transform: none; }
.uppercase { text-transform: uppercase; }
.capitalize { text-transform: capitalize; }
.white-space-normal { white-space: normal; }
.user-select-none { user-select: none; }
.user-select-text { user-select: text; }

.wrap-all {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  hyphens: auto;
}

.hover-underline {
  text-decoration: none;
}
.hover-underline:hover, .hover-target:hover .hover-underline {
  text-decoration: underline;
}

.h7 {
  font-size: 0.625rem;
}

/* ========== MISC ========== */

.appearance-none { appearance: none; }
.box-shadow-none { box-shadow: none; }
.outline-none { outline: none; }
.pointer { cursor: pointer; }
.not-allowed { cursor: not-allowed; }
.move-cursor { cursor: move; }
.pointer-events-none { pointer-events: none; }


.box-shadow1 {
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12);
}

.box-shadow2 {
  box-shadow: 0 3px 5px -1px rgba(0,0,0,0.2), 0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12);
}

.br1 {
  border-radius: 4px;
}

.br2 {
  border-radius: 8px;
}

.br3 {
  border-radius: 16px;
}

.brt1 {
  border-radius: 4px 4px 0 0 !important;
}

.brb1 {
  border-radius: 0 0 4px 4px !important;
}

.round {
  border-radius: 50%;
}

.chip-radius {
  border-radius: 100px;
}

.pill {
  width: 30px;
  height: 20px;
  border-radius: 10px;
}

/* ========== COLORS ========== */

.o0 { opacity: 0; }
.o25 { opacity: 0.25; }
.o50 { opacity: 0.5; }
.o75 { opacity: 0.75; }
.o80 { opacity: 0.8; }
.o100 { opacity: 1; }

.bg-darken-5 { background-color: rgba(0, 0, 0, 0.95); }
.bg-lighten-5 { background-color: rgba(255, 255, 255, 0.95); }
.bg-transparent { background-color: transparent; }

.border-transparent { border-color: transparent; }
.color-inherit { color: inherit; }

.white  { color: var(--white); }
.black  { color: var(--black); }
.bg-white   { background-color: var(--white); }
.bg-black  { background-color: var(--black); }

.grey-50 { color: var(--grey-50); }
.grey-100 { color: var(--grey-100); }
.grey-200 { color: var(--grey-200); }
.grey-300 { color: var(--grey-300); }
.grey-400 { color: var(--grey-400); }
.grey-500 { color: var(--grey-500); }
.grey-600 { color: var(--grey-600); }
.grey-700 { color: var(--grey-700); }
.grey-800 { color: var(--grey-800); }
.grey-900 { color: var(--grey-900); }

.hover-grey-50:hover { color: var(--grey-50); }
.hover-grey-100:hover { color: var(--grey-100); }
.hover-grey-200:hover { color: var(--grey-200); }
.hover-grey-300:hover { color: var(--grey-300); }
.hover-grey-400:hover { color: var(--grey-400); }
.hover-grey-500:hover { color: var(--grey-500); }
.hover-grey-600:hover { color: var(--grey-600); }
.hover-grey-700:hover { color: var(--grey-700); }
.hover-grey-800:hover { color: var(--grey-800); }
.hover-grey-900:hover { color: var(--grey-900); }

.bg-grey-50 { background: var(--grey-50); }
.bg-grey-100 { background: var(--grey-100); }
.bg-grey-200 { background: var(--grey-200); }
.bg-grey-300 { background: var(--grey-300); }
.bg-grey-400 { background: var(--grey-400); }
.bg-grey-500 { background: var(--grey-500); }
.bg-grey-600 { background: var(--grey-600); }
.bg-grey-700 { background: var(--grey-700); }
.bg-grey-800 { background: var(--grey-800); }
.bg-grey-900 { background: var(--grey-900); }

.hover-bg-grey-50:hover { background: var(--grey-50); }
.hover-bg-grey-100:hover { background: var(--grey-100); }
.hover-bg-grey-200:hover { background: var(--grey-200); }
.hover-bg-grey-300:hover { background: var(--grey-300); }
.hover-bg-grey-400:hover { background: var(--grey-400); }
.hover-bg-grey-500:hover { background: var(--grey-500); }
.hover-bg-grey-600:hover { background: var(--grey-600); }
.hover-bg-grey-700:hover { background: var(--grey-700); }
.hover-bg-grey-800:hover { background: var(--grey-800); }
.hover-bg-grey-900:hover { background: var(--grey-900); }

.border-grey-50 { border-color: var(--grey-50); }
.border-grey-100 { border-color: var(--grey-100); }
.border-grey-200 { border-color: var(--grey-200); }
.border-grey-300 { border-color: var(--grey-300); }
.border-grey-400 { border-color: var(--grey-400); }
.border-grey-500 { border-color: var(--grey-500); }
.border-grey-600 { border-color: var(--grey-600); }
.border-grey-700 { border-color: var(--grey-700); }
.border-grey-800 { border-color: var(--grey-800); }
.border-grey-900 { border-color: var(--grey-900); }

.amber-50 { color: var(--amber-50); }
.amber-100 { color: var(--amber-100); }
.amber-200 { color: var(--amber-200); }
.amber-300 { color: var(--amber-300); }
.amber-400 { color: var(--amber-400); }
.amber-500 { color: var(--amber-500); }
.amber-600 { color: var(--amber-600); }
.amber-700 { color: var(--amber-700); }
.amber-800 { color: var(--amber-800); }
.amber-900 { color: var(--amber-900); }

.bg-amber-50 { background: var(--amber-50); }
.bg-amber-100 { background: var(--amber-100); }
.bg-amber-200 { background: var(--amber-200); }
.bg-amber-300 { background: var(--amber-300); }
.bg-amber-400 { background: var(--amber-400); }
.bg-amber-500 { background: var(--amber-500); }
.bg-amber-600 { background: var(--amber-600); }
.bg-amber-700 { background: var(--amber-700); }
.bg-amber-800 { background: var(--amber-800); }
.bg-amber-900 { background: var(--amber-900); }

.border-amber-50 { border-color: var(--amber-50); }
.border-amber-100 { border-color: var(--amber-100); }
.border-amber-200 { border-color: var(--amber-200); }
.border-amber-300 { border-color: var(--amber-300); }
.border-amber-400 { border-color: var(--amber-400); }
.border-amber-500 { border-color: var(--amber-500); }
.border-amber-600 { border-color: var(--amber-600); }
.border-amber-700 { border-color: var(--amber-700); }
.border-amber-800 { border-color: var(--amber-800); }
.border-amber-900 { border-color: var(--amber-900); }

.bg-warning-lightest { background: var(--warning-lightest); }

.red-50 { color: var(--red-50); }
.red-100 { color: var(--red-100); }
.red-200 { color: var(--red-200); }
.red-300 { color: var(--red-300); }
.red-400 { color: var(--red-400); }
.red-500 { color: var(--red-500); }
.red-600 { color: var(--red-600); }
.red-700 { color: var(--red-700); }
.red-800 { color: var(--red-800); }
.red-900 { color: var(--red-900); }

.bg-red-50 { background: var(--red-50); }
.bg-red-100 { background: var(--red-100); }
.bg-red-200 { background: var(--red-200); }
.bg-red-300 { background: var(--red-300); }
.bg-red-400 { background: var(--red-400); }
.bg-red-500 { background: var(--red-500); }
.bg-red-600 { background: var(--red-600); }
.bg-red-700 { background: var(--red-700); }
.bg-red-800 { background: var(--red-800); }
.bg-red-900 { background: var(--red-900); }

.border-red-50 { border-color: var(--red-50); }
.border-red-100 { border-color: var(--red-100); }
.border-red-200 { border-color: var(--red-200); }
.border-red-300 { border-color: var(--red-300); }
.border-red-400 { border-color: var(--red-400); }
.border-red-500 { border-color: var(--red-500); }
.border-red-600 { border-color: var(--red-600); }
.border-red-700 { border-color: var(--red-700); }
.border-red-800 { border-color: var(--red-800); }
.border-red-900 { border-color: var(--red-900); }

.green-50 { color: var(--green-50); }
.green-100 { color: var(--green-100); }
.green-200 { color: var(--green-200); }
.green-300 { color: var(--green-300); }
.green-400 { color: var(--green-400); }
.green-500 { color: var(--green-500); }
.green-600 { color: var(--green-600); }
.green-700 { color: var(--green-700); }
.green-800 { color: var(--green-800); }
.green-900 { color: var(--green-900); }

.bg-green-50 { background: var(--green-50); }
.bg-green-100 { background: var(--green-100); }
.bg-green-200 { background: var(--green-200); }
.bg-green-300 { background: var(--green-300); }
.bg-green-400 { background: var(--green-400); }
.bg-green-500 { background: var(--green-500); }
.bg-green-600 { background: var(--green-600); }
.bg-green-700 { background: var(--green-700); }
.bg-green-800 { background: var(--green-800); }
.bg-green-900 { background: var(--green-900); }

.hover-bg-green-50:hover { background: var(--green-50); }
.hover-bg-green-100:hover { background: var(--green-100); }
.hover-bg-green-200:hover { background: var(--green-200); }
.hover-bg-green-300:hover { background: var(--green-300); }
.hover-bg-green-400:hover { background: var(--green-400); }
.hover-bg-green-500:hover { background: var(--green-500); }
.hover-bg-green-600:hover { background: var(--green-600); }
.hover-bg-green-700:hover { background: var(--green-700); }
.hover-bg-green-800:hover { background: var(--green-800); }
.hover-bg-green-900:hover { background: var(--green-900); }

.border-green-50 { border-color: var(--green-50); }
.border-green-100 { border-color: var(--green-100); }
.border-green-200 { border-color: var(--green-200); }
.border-green-300 { border-color: var(--green-300); }
.border-green-400 { border-color: var(--green-400); }
.border-green-500 { border-color: var(--green-500); }
.border-green-600 { border-color: var(--green-600); }
.border-green-700 { border-color: var(--green-700); }
.border-green-800 { border-color: var(--green-800); }
.border-green-900 { border-color: var(--green-900); }

.purple-50 { color: var(--purple-50); }
.purple-100 { color: var(--purple-100); }
.purple-200 { color: var(--purple-200); }
.purple-300 { color: var(--purple-300); }
.purple-400 { color: var(--purple-400); }
.purple-500 { color: var(--purple-500); }
.purple-600 { color: var(--purple-600); }
.purple-700 { color: var(--purple-700); }
.purple-800 { color: var(--purple-800); }
.purple-900 { color: var(--purple-900); }

.bg-purple-50 { background: var(--purple-50); }
.bg-purple-100 { background: var(--purple-100); }
.bg-purple-200 { background: var(--purple-200); }
.bg-purple-300 { background: var(--purple-300); }
.bg-purple-400 { background: var(--purple-400); }
.bg-purple-500 { background: var(--purple-500); }
.bg-purple-600 { background: var(--purple-600); }
.bg-purple-700 { background: var(--purple-700); }
.bg-purple-800 { background: var(--purple-800); }
.bg-purple-900 { background: var(--purple-900); }

.hover-bg-purple-50:hover { background: var(--purple-50); }
.hover-bg-purple-100:hover { background: var(--purple-100); }
.hover-bg-purple-200:hover { background: var(--purple-200); }
.hover-bg-purple-300:hover { background: var(--purple-300); }
.hover-bg-purple-400:hover { background: var(--purple-400); }
.hover-bg-purple-500:hover { background: var(--purple-500); }
.hover-bg-purple-600:hover { background: var(--purple-600); }
.hover-bg-purple-700:hover { background: var(--purple-700); }
.hover-bg-purple-800:hover { background: var(--purple-800); }
.hover-bg-purple-900:hover { background: var(--purple-900); }

.pink-50 { color: var(--pink-50); }
.pink-100 { color: var(--pink-100); }
.pink-200 { color: var(--pink-200); }
.pink-300 { color: var(--pink-300); }
.pink-400 { color: var(--pink-400); }
.pink-500 { color: var(--pink-500); }
.pink-600 { color: var(--pink-600); }
.pink-700 { color: var(--pink-700); }
.pink-800 { color: var(--pink-800); }
.pink-900 { color: var(--pink-900); }

.bg-pink-50 { background: var(--pink-50); }
.bg-pink-100 { background: var(--pink-100); }
.bg-pink-200 { background: var(--pink-200); }
.bg-pink-300 { background: var(--pink-300); }
.bg-pink-400 { background: var(--pink-400); }
.bg-pink-500 { background: var(--pink-500); }
.bg-pink-600 { background: var(--pink-600); }
.bg-pink-700 { background: var(--pink-700); }
.bg-pink-800 { background: var(--pink-800); }
.bg-pink-900 { background: var(--pink-900); }

.border-pink-50 { border-color: var(--pink-50); }
.border-pink-100 { border-color: var(--pink-100); }
.border-pink-200 { border-color: var(--pink-200); }
.border-pink-300 { border-color: var(--pink-300); }
.border-pink-400 { border-color: var(--pink-400); }
.border-pink-500 { border-color: var(--pink-500); }
.border-pink-600 { border-color: var(--pink-600); }
.border-pink-700 { border-color: var(--pink-700); }
.border-pink-800 { border-color: var(--pink-800); }
.border-pink-900 { border-color: var(--pink-900); }

.blue-50 { color: var(--blue-50); }
.blue-100 { color: var(--blue-100); }
.blue-200 { color: var(--blue-200); }
.blue-300 { color: var(--blue-300); }
.blue-400 { color: var(--blue-400); }
.blue-500 { color: var(--blue-500); }
.blue-600 { color: var(--blue-600); }
.blue-700 { color: var(--blue-700); }
.blue-800 { color: var(--blue-800); }
.blue-900 { color: var(--blue-900); }

.bg-blue-50 { background: var(--blue-50); }
.bg-blue-100 { background: var(--blue-100); }
.bg-blue-200 { background: var(--blue-200); }
.bg-blue-300 { background: var(--blue-300); }
.bg-blue-400 { background: var(--blue-400); }
.bg-blue-500 { background: var(--blue-500); }
.bg-blue-600 { background: var(--blue-600); }
.bg-blue-700 { background: var(--blue-700); }
.bg-blue-800 { background: var(--blue-800); }
.bg-blue-900 { background: var(--blue-900); }

.border-blue-50 { border-color: var(--blue-50); }
.border-blue-100 { border-color: var(--blue-100); }
.border-blue-200 { border-color: var(--blue-200); }
.border-blue-300 { border-color: var(--blue-300); }
.border-blue-400 { border-color: var(--blue-400); }
.border-blue-500 { border-color: var(--blue-500); }
.border-blue-600 { border-color: var(--blue-600); }
.border-blue-700 { border-color: var(--blue-700); }
.border-blue-800 { border-color: var(--blue-800); }
.border-blue-900 { border-color: var(--blue-900); }

.candidate { color: var(--blue-600); } /* NOTE: It's probably safer to leave this class around for now as it's nigh impossible to search for... */

.fg-error { color: var(--error-main); }
.fg-warning { color: var(--warning-main); }
.fg-warning-dark { color: var(--warning-dark); }
.fg-info { color: var(--info-main); }
.fg-success { color: var(--success-main); }

.bg-error { background-color: var(--error-main); }
.bg-warning { background-color: var(--warning-main); }
.bg-info { background-color: var(--info-main); }
.bg-success { background-color: var(--success-main); }

.bg-none { background: none; }
.bg-primary-hover { background-color: rgba(65, 185, 126, 0.08); }

.border-warning { border-color: var(--warning-main); }
.border-error { border-color: var(--error-main); }

/* ========== ANIMATION ========== */

.duration-1 { transition-duration: 0.146s; }
.duration-2 { transition-duration: 0.236s; }
.duration-3 { transition-duration: 0.382s; }
.duration-4 { transition-duration: 0.618s; }
.duration-5 { transition-duration: 1s; }

.delay-1 { transition-delay: 0.146s; }
.delay-2 { transition-delay: 0.236s; }
.delay-3 { transition-delay: 0.382s; }
.delay-4 { transition-delay: 0.618s; }
.delay-5 { transition-delay: 1s; }

.ease { transition-timing-function: ease; }
.ease-in { transition-timing-function: ease-in; }
.ease-out { transition-timing-function: ease-out; }
.ease-in-out { transition-timing-function: ease-in-out; }
.linear { transition-timing-function: linear; }
.step-start { transition-timing-function: step-start; }
.step-end { transition-timing-function: step-end; }

.rotate-n360 { transform: rotate(-360deg); }
.rotate-n315 { transform: rotate(-315deg); }
.rotate-n270 { transform: rotate(-270deg); }
.rotate-n225 { transform: rotate(-225deg); }
.rotate-n180 { transform: rotate(-180deg); }
.rotate-n135 { transform: rotate(-135deg); }
.rotate-n90 { transform: rotate(-90deg); }
.rotate-n45 { transform: rotate(-45deg); }
.rotate-0 { transform: rotate(0); }
.rotate-45 { transform: rotate(45deg); }
.rotate-90 { transform: rotate(90deg); }
.rotate-135 { transform: rotate(135deg); }
.rotate-180 { transform: rotate(180deg); }
.rotate-225 { transform: rotate(225deg); }
.rotate-270 { transform: rotate(270deg); }
.rotate-315 { transform: rotate(315deg); }
.rotate-360 { transform: rotate(360deg); }

.scale-075 { transform: scale(0.75); }
.scale-1 { transform: scale(1); }

.rotatable {
  transition: transform 0.3s;
}
.rotatable-fast {
  transition: transform 0.1s;
}
  .rotatable-open {
    transform: rotate(0);
  }
  .rotatable-closed {
    transform: rotate(180deg);
  }

.semibold {
  font-weight: 500;
}

.card-outline {
  border-radius: 4px;
  border-style: solid;
  border-color: var(--grey-300);
  border-width: 1px;
}

.card-outline-light {
  border-radius: 4px;
  border-style: solid;
  border-color: var(--grey-100);
  border-width: 1px;
}

.border-bottom-thicc {
  border-bottom: 2px solid #000;
}

.grey-border {
  border-style: solid;
  border-color: var(--grey-300);
  border-width: 1px;
}

.grey-border-bottom {
  border-width: 0;
  border-style: solid;
  border-bottom-color: var(--grey-300);
  border-bottom-width: 1px;
}

.grey-border-top {
  border-width: 0;
  border-style: solid;
  border-top-color: var(--grey-300);
  border-top-width: 1px;
}

.grey-border-right {
  border-width: 0;
  border-style: solid;
  border-right-color: var(--grey-300);
  border-right-width: 1px;
}

.grey-border-left {
  border-width: 0;
  border-style: solid;
  border-left-color: var(--grey-300);
  border-left-width: 1px;
}

.lt-grey-border {
  border-style: solid;
  border-color: var(--grey-200);
  border-width: 1px;
}

.lt-grey-border-left {
  border-width: 0;
  border-style: solid;
  border-left-color: var(--grey-200);
  border-left-width: 1px;
}

.lt-grey-border-right {
  border-width: 0;
  border-style: solid;
  border-right-color: var(--grey-200);
  border-right-width: 1px;
}

.lt-grey-border-top {
  border-width: 0;
  border-style: solid;
  border-top-color: var(--grey-200);
  border-top-width: 1px;
}

.lt-grey-border-bottom {
  border-width: 0;
  border-style: solid;
  border-bottom-color: var(--grey-200);
  border-bottom-width: 1px;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.min-full-height {
  min-height: 100%;
}

.full-height-p1 {
  height: calc(100% - 8px);
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.z0 { z-index: 0; }

.sm-dots {
  list-style-type: none;
  position: relative;
  padding-left: 2rem;
}
.sm-dots li::before {
  content: "•";
  position: absolute;
  left: 1rem;
}

.reveal-on-hover {
  visibility: hidden;
}
.hover-target:hover .reveal-on-hover {
  visibility: visible;
}
.hide-on-hover {
  visibility: visible;
}
.hover-target:hover .hide-on-hover {
  visibility: hidden;
}

.list-item-hover:hover {
  background-color: var(--green-50);
}

.animated-bg {
  transition: background-color 100ms ease-in-out;
}

.dark-placeholder::placeholder {
  color: var(--grey-800);
  opacity: 1;
}