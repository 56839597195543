:root {
  /* Default values from BassCSS */
  --black: #111;
  --white: #fff;

  /* Talentpair Green (Primary) */
  --green-50: #e4f5ec;
  --green-100: #bfe5d0;
  --green-200: #95d5b3;
  --green-300: #68c595;
  --green-400: #41b97f;
  --green-500: #00ad69;
  --green-600: #009e5f;
  --green-700: #008c52;
  --green-800: #007a46;
  --green-900: #005b31;
  --green-main: #00ad69;
  --green-light: #77ecae;
  --green-dark: #008851;

  /* Talentpair Pink (Secondary) */
  --pink-50: #f9e7f1;
  --pink-100: #f1c2dd;
  --pink-200: #eb9cc6;
  --pink-300: #e676af;
  --pink-400: #e35c9c;
  --pink-500: #e2498a;
  --pink-600: #d04584;
  --pink-700: #b9417b;
  --pink-800: #a33d73;
  --pink-900: #7b3663;
  --pink-main: #b9417b;
  --pink-light: #ee72aa;
  --pink-dark: #85004f;

  /* Talentpair Purple (Triadic) */
  --purple-50: #f0e8f6;
  --purple-100: #d8c7ea;
  --purple-200: #bea2dd;
  --purple-300: #a57ccf;
  --purple-400: #925ec4;
  --purple-500: #7f41b9;
  --purple-600: #763bb3;
  --purple-700: #6a33a9;
  --purple-800: #5f2da1;
  --purple-900: #4e2093;
  --purple-main: #7f41b9;
  --purple-light: #b26fec;
  --purple-dark: #4d1188;

  /* Talentpair Grey */
  --grey-50: #fafafa;
  --grey-100: #f5f5f5;
  --grey-200: #eee;
  --grey-300: #e0e0e0;
  --grey-400: #bdbdbd;
  --grey-500: #9e9e9e;
  --grey-600: #757575;
  --grey-700: #616161;
  --grey-800: #424242;
  --grey-900: #212121;

  /* Talentpair Amber */
  --amber-50: #fff8e1;
  --amber-100: #ffecb3;
  --amber-200: #ffe082;
  --amber-300: #ffd54f;
  --amber-400: #ffca28;
  --amber-500: #ffc107;
  --amber-600: #ffb300;
  --amber-700: #ffa000;
  --amber-800: #ff8f00;
  --amber-900: #ff6f00;

  /* Talentpair Red */
  --red-50: #ffebee;
  --red-100: #ffcdd2;
  --red-200: #ef9a9a;
  --red-300: #e57373;
  --red-400: #ef5350;
  --red-500: #f44336;
  --red-600: #e53935;
  --red-700: #d32f2f;
  --red-800: #c62828;
  --red-900: #b71c1c;

  /* Talentpair Blue */
  --blue-50: #e7edfe;
  --blue-100: #c3d3fc;
  --blue-200: #9bb5fa;
  --blue-300: #7297f7;
  --blue-400: #5481f6;
  --blue-500: #366bf4;
  --blue-600: #3063f3;
  --blue-700: #2958f1;
  --blue-800: #224eef;
  --blue-900: #163cec;

  /* Semantic colors */
  --error-main: #d9152c;
  --warning-main: #eb641e;
  --warning-dark: #5e280c;
  --info-main: #3e77ef;
  --success-main: #0b9d4b;
  --warning-lightest: #fff4ec;

  /* Colors for Quest Groups brand pages */
  --qg-primary: #ef4240;
  --qg-seconday: #ed9121;

  /* whitespace widths for margin/padding */
  --space-0: 0;
  --space-1: 4px;
  --space-2: 8px;
  --space-3: 16px;
  --space-4: 24px;
  --space-5: 32px;
  --space-6: 40px;
  --space-7: 48px;
  --space-8: 56px;
  --space-9: 64px;
  --space-10: 72px;
  --space-11: 80px;
}
