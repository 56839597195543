/* Forked from Basscss Basic: https://github.com/basscss/basic */

* {
  box-sizing: border-box;
}

body {
  /* sytem-ui fallback stack through postcss, then emoji stack */
  font-family: system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  font-variant: normal;
  line-height: 1.5;
  margin: 0;
  color: #111;
  background-color: #fff;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
}

img {
  max-width: 100%;
  height: auto;
}

svg {
  max-height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  line-height: 1.25;
  margin-top: 1em;
  margin-bottom: 0.5em;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 1rem;
}

h5 {
  font-size: 0.875rem;
}

h6 {
  font-size: 0.75rem;
}

p,
dl,
ol,
ul,
pre,
blockquote {
  margin-top: 1em;
  margin-bottom: 1em;
}

a {
  color: #32aa6b;
  cursor: pointer;
  text-decoration: none;
}

/* FIXED POSITION UNDER APP BAR - here because we just need some global classes. */
.fixed-under-appbar {
  top: 56px;
}

.appbar-icon {
  height: 50px;
}

@media screen and (min-width: 0) and (orientation: landscape) {
  .fixed-under-appbar {
    top: 48px;
  }
  .appbar-icon {
    height: 42px;
  }
}

@media screen and (min-width: 600px) {
  .fixed-under-appbar {
    top: 64px;
  }
  .appbar-icon {
    height: 58px;
  }
}
