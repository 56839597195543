@import "./variables.css";

.p0 {
  padding: 0;
}
.pt0 {
  padding-top: 0;
}
.pr0 {
  padding-right: 0;
}
.pb0 {
  padding-bottom: 0;
}
.pl0 {
  padding-left: 0;
}
.px0 {
  padding-left: 0;
  padding-right: 0;
}
.py0 {
  padding-top: 0;
  padding-bottom: 0;
}

.p1 {
  padding: var(--space-1);
}
.pt1 {
  padding-top: var(--space-1);
}
.pr1 {
  padding-right: var(--space-1);
}
.pb1 {
  padding-bottom: var(--space-1);
}
.pl1 {
  padding-left: var(--space-1);
}
.py1 {
  padding-top: var(--space-1);
  padding-bottom: var(--space-1);
}
.px1 {
  padding-left: var(--space-1);
  padding-right: var(--space-1);
}

.p2 {
  padding: var(--space-2);
}
.pt2 {
  padding-top: var(--space-2);
}
.pr2 {
  padding-right: var(--space-2);
}
.pb2 {
  padding-bottom: var(--space-2);
}
.pl2 {
  padding-left: var(--space-2);
}
.py2 {
  padding-top: var(--space-2);
  padding-bottom: var(--space-2);
}
.px2 {
  padding-left: var(--space-2);
  padding-right: var(--space-2);
}

.p3 {
  padding: var(--space-3);
}
.pt3 {
  padding-top: var(--space-3);
}
.pr3 {
  padding-right: var(--space-3);
}
.pb3 {
  padding-bottom: var(--space-3);
}
.pl3 {
  padding-left: var(--space-3);
}
.py3 {
  padding-top: var(--space-3);
  padding-bottom: var(--space-3);
}
.px3 {
  padding-left: var(--space-3);
  padding-right: var(--space-3);
}

.p4 {
  padding: var(--space-4);
}
.pt4 {
  padding-top: var(--space-4);
}
.pr4 {
  padding-right: var(--space-4);
}
.pb4 {
  padding-bottom: var(--space-4);
}
.pl4 {
  padding-left: var(--space-4);
}
.py4 {
  padding-top: var(--space-4);
  padding-bottom: var(--space-4);
}
.px4 {
  padding-left: var(--space-4);
  padding-right: var(--space-4);
}

.p5 {
  padding: var(--space-5);
}
.pt5 {
  padding-top: var(--space-5);
}
.pr5 {
  padding-right: var(--space-5);
}
.pb5 {
  padding-bottom: var(--space-5);
}
.pl5 {
  padding-left: var(--space-5);
}
.px5 {
  padding-left: var(--space-5);
  padding-right: var(--space-5);
}
.py5 {
  padding-top: var(--space-5);
  padding-bottom: var(--space-5);
}

.p6 {
  padding: var(--space-6);
}
.pt6 {
  padding-top: var(--space-6);
}
.pr6 {
  padding-right: var(--space-6);
}
.pb6 {
  padding-bottom: var(--space-6);
}
.pl6 {
  padding-left: var(--space-6);
}
.px6 {
  padding-left: var(--space-6);
  padding-right: var(--space-6);
}
.py6 {
  padding-top: var(--space-6);
  padding-bottom: var(--space-6);
}

.p7 {
  padding: var(--space-7);
}
.pt7 {
  padding-top: var(--space-7);
}
.pr7 {
  padding-right: var(--space-7);
}
.pb7 {
  padding-bottom: var(--space-7);
}
.pl7 {
  padding-left: var(--space-7);
}
.px7 {
  padding-left: var(--space-7);
  padding-right: var(--space-7);
}
.py7 {
  padding-top: var(--space-7);
  padding-bottom: var(--space-7);
}

.p8 {
  padding: var(--space-8);
}
.pt8 {
  padding-top: var(--space-8);
}
.pr8 {
  padding-right: var(--space-8);
}
.pb8 {
  padding-bottom: var(--space-8);
}
.pl8 {
  padding-left: var(--space-8);
}
.px8 {
  padding-left: var(--space-8);
  padding-right: var(--space-8);
}
.py8 {
  padding-top: var(--space-8);
  padding-bottom: var(--space-8);
}

.p9 {
  padding: var(--space-9);
}
.pt9 {
  padding-top: var(--space-9);
}
.pr9 {
  padding-right: var(--space-9);
}
.pb9 {
  padding-bottom: var(--space-9);
}
.pl9 {
  padding-left: var(--space-9);
}
.px9 {
  padding-left: var(--space-9);
  padding-right: var(--space-9);
}
.py9 {
  padding-top: var(--space-9);
  padding-bottom: var(--space-9);
}

.p10 {
  padding: var(--space-10);
}
.pt10 {
  padding-top: var(--space-10);
}
.pr10 {
  padding-right: var(--space-10);
}
.pb10 {
  padding-bottom: var(--space-10);
}
.pl10 {
  padding-left: var(--space-10);
}
.px10 {
  padding-left: var(--space-10);
  padding-right: var(--space-10);
}
.py10 {
  padding-top: var(--space-10);
  padding-bottom: var(--space-10);
}

.p11 {
  padding: var(--space-11);
}
.pt11 {
  padding-top: var(--space-11);
}
.pr11 {
  padding-right: var(--space-11);
}
.pb11 {
  padding-bottom: var(--space-11);
}
.pl11 {
  padding-left: var(--space-11);
}
.px11 {
  padding-left: var(--space-11);
  padding-right: var(--space-11);
}
.py11 {
  padding-top: var(--space-11);
  padding-bottom: var(--space-11);
}
