@import "./variables.css";

.m0 {
  margin: 0;
}
.mt0 {
  margin-top: 0;
}
.mr0 {
  margin-right: 0;
}
.mb0 {
  margin-bottom: 0;
}
.ml0 {
  margin-left: 0;
}
.mx0 {
  margin-left: 0;
  margin-right: 0;
}
.my0 {
  margin-top: 0;
  margin-bottom: 0;
}

.m1 {
  margin: var(--space-1);
}
.mt1 {
  margin-top: var(--space-1);
}
.mr1 {
  margin-right: var(--space-1);
}
.mb1 {
  margin-bottom: var(--space-1);
}
.ml1 {
  margin-left: var(--space-1);
}
.mx1 {
  margin-left: var(--space-1);
  margin-right: var(--space-1);
}
.my1 {
  margin-top: var(--space-1);
  margin-bottom: var(--space-1);
}

.m2 {
  margin: var(--space-2);
}
.mt2 {
  margin-top: var(--space-2);
}
.mr2 {
  margin-right: var(--space-2);
}
.mb2 {
  margin-bottom: var(--space-2);
}
.ml2 {
  margin-left: var(--space-2);
}
.mx2 {
  margin-left: var(--space-2);
  margin-right: var(--space-2);
}
.my2 {
  margin-top: var(--space-2);
  margin-bottom: var(--space-2);
}

.m3 {
  margin: var(--space-3);
}
.mt3 {
  margin-top: var(--space-3);
}
.mr3 {
  margin-right: var(--space-3);
}
.mb3 {
  margin-bottom: var(--space-3);
}
.ml3 {
  margin-left: var(--space-3);
}
.mx3 {
  margin-left: var(--space-3);
  margin-right: var(--space-3);
}
.my3 {
  margin-top: var(--space-3);
  margin-bottom: var(--space-3);
}

.m4 {
  margin: var(--space-4);
}
.mt4 {
  margin-top: var(--space-4);
}
.mr4 {
  margin-right: var(--space-4);
}
.mb4 {
  margin-bottom: var(--space-4);
}
.ml4 {
  margin-left: var(--space-4);
}
.mx4 {
  margin-left: var(--space-4);
  margin-right: var(--space-4);
}
.my4 {
  margin-top: var(--space-4);
  margin-bottom: var(--space-4);
}

.m5 {
  margin: var(--space-5);
}
.mt5 {
  margin-top: var(--space-5);
}
.mr5 {
  margin-right: var(--space-5);
}
.mb5 {
  margin-bottom: var(--space-5);
}
.ml5 {
  margin-left: var(--space-5);
}
.mx5 {
  margin-left: var(--space-5);
  margin-right: var(--space-5);
}
.my5 {
  margin-top: var(--space-5);
  margin-bottom: var(--space-5);
}

.m6 {
  margin: var(--space-6);
}
.mt6 {
  margin-top: var(--space-6);
}
.mr6 {
  margin-right: var(--space-6);
}
.mb6 {
  margin-bottom: var(--space-6);
}
.ml6 {
  margin-left: var(--space-6);
}
.mx6 {
  margin-left: var(--space-6);
  margin-right: var(--space-6);
}
.my6 {
  margin-top: var(--space-6);
  margin-bottom: var(--space-6);
}

.m7 {
  margin: var(--space-7);
}
.mt7 {
  margin-top: var(--space-7);
}
.mr7 {
  margin-right: var(--space-7);
}
.mb7 {
  margin-bottom: var(--space-7);
}
.ml7 {
  margin-left: var(--space-7);
}
.mx7 {
  margin-left: var(--space-7);
  margin-right: var(--space-7);
}
.my7 {
  margin-top: var(--space-7);
  margin-bottom: var(--space-7);
}

.m8 {
  margin: var(--space-8);
}
.mt8 {
  margin-top: var(--space-8);
}
.mr8 {
  margin-right: var(--space-8);
}
.mb8 {
  margin-bottom: var(--space-8);
}
.ml8 {
  margin-left: var(--space-8);
}
.mx8 {
  margin-left: var(--space-8);
  margin-right: var(--space-8);
}
.my8 {
  margin-top: var(--space-8);
  margin-bottom: var(--space-8);
}

.m9 {
  margin: var(--space-9);
}
.mt9 {
  margin-top: var(--space-9);
}
.mr9 {
  margin-right: var(--space-9);
}
.mb9 {
  margin-bottom: var(--space-9);
}
.ml9 {
  margin-left: var(--space-9);
}
.mx9 {
  margin-left: var(--space-9);
  margin-right: var(--space-9);
}
.my9 {
  margin-top: var(--space-9);
  margin-bottom: var(--space-9);
}

.m10 {
  margin: var(--space-10);
}
.mt10 {
  margin-top: var(--space-10);
}
.mr10 {
  margin-right: var(--space-10);
}
.mb10 {
  margin-bottom: var(--space-10);
}
.ml10 {
  margin-left: var(--space-10);
}
.mx10 {
  margin-left: var(--space-10);
  margin-right: var(--space-10);
}
.my10 {
  margin-top: var(--space-10);
  margin-bottom: var(--space-10);
}

.m11 {
  margin: var(--space-11);
}
.mt11 {
  margin-top: var(--space-11);
}
.mr11 {
  margin-right: var(--space-11);
}
.mb11 {
  margin-bottom: var(--space-11);
}
.ml11 {
  margin-left: var(--space-11);
}
.mx11 {
  margin-left: var(--space-11);
  margin-right: var(--space-11);
}
.my11 {
  margin-top: var(--space-11);
  margin-bottom: var(--space-11);
}

.mln1 {
  margin-left: calc(var(--space-1) * -1);
}
.mln2 {
  margin-left: calc(var(--space-2) * -1);
}
.mln3 {
  margin-left: calc(var(--space-3) * -1);
}
.mln4 {
  margin-left: calc(var(--space-4) * -1);
}
.mln5 {
  margin-left: calc(var(--space-5) * -1);
}
.mln6 {
  margin-left: calc(var(--space-6) * -1);
}
.mln7 {
  margin-left: calc(var(--space-7) * -1);
}
.mln8 {
  margin-left: calc(var(--space-8) * -1);
}
.mln9 {
  margin-left: calc(var(--space-9) * -1);
}
.mln10 {
  margin-left: calc(var(--space-10) * -1);
}
.mln11 {
  margin-left: calc(var(--space-11) * -1);
}

.mrn1 {
  margin-right: calc(var(--space-1) * -1);
}
.mrn2 {
  margin-right: calc(var(--space-2) * -1);
}
.mrn3 {
  margin-right: calc(var(--space-3) * -1);
}
.mrn4 {
  margin-right: calc(var(--space-4) * -1);
}
.mrn5 {
  margin-right: calc(var(--space-5) * -1);
}
.mrn6 {
  margin-right: calc(var(--space-6) * -1);
}
.mrn7 {
  margin-right: calc(var(--space-7) * -1);
}
.mrn8 {
  margin-right: calc(var(--space-8) * -1);
}
.mrn9 {
  margin-right: calc(var(--space-9) * -1);
}
.mrn10 {
  margin-right: calc(var(--space-10) * -1);
}
.mrn11 {
  margin-right: calc(var(--space-11) * -1);
}

.mtn1 {
  margin-top: calc(var(--space-1) * -1);
}
.mtn2 {
  margin-top: calc(var(--space-2) * -1);
}
.mtn3 {
  margin-top: calc(var(--space-3) * -1);
}
.mtn4 {
  margin-top: calc(var(--space-4) * -1);
}
.mtn5 {
  margin-top: calc(var(--space-5) * -1);
}
.mtn6 {
  margin-top: calc(var(--space-6) * -1);
}
.mtn7 {
  margin-top: calc(var(--space-7) * -1);
}
.mtn8 {
  margin-top: calc(var(--space-8) * -1);
}
.mtn9 {
  margin-top: calc(var(--space-9) * -1);
}
.mtn10 {
  margin-top: calc(var(--space-10) * -1);
}
.mtn11 {
  margin-top: calc(var(--space-11) * -1);
}

.mbn1 {
  margin-bottom: calc(var(--space-1) * -1);
}
.mbn2 {
  margin-bottom: calc(var(--space-2) * -1);
}
.mbn3 {
  margin-bottom: calc(var(--space-3) * -1);
}
.mbn4 {
  margin-bottom: calc(var(--space-4) * -1);
}
.mbn5 {
  margin-bottom: calc(var(--space-5) * -1);
}
.mbn6 {
  margin-bottom: calc(var(--space-6) * -1);
}
.mbn7 {
  margin-bottom: calc(var(--space-7) * -1);
}
.mbn8 {
  margin-bottom: calc(var(--space-8) * -1);
}
.mbn9 {
  margin-bottom: calc(var(--space-9) * -1);
}
.mbn10 {
  margin-bottom: calc(var(--space-10) * -1);
}
.mbn11 {
  margin-bottom: calc(var(--space-11) * -1);
}

.mxn1 {
  margin-left: calc(var(--space-1) * -1);
  margin-right: calc(var(--space-1) * -1);
}
.mxn2 {
  margin-left: calc(var(--space-2) * -1);
  margin-right: calc(var(--space-2) * -1);
}
.mxn3 {
  margin-left: calc(var(--space-3) * -1);
  margin-right: calc(var(--space-3) * -1);
}
.mxn4 {
  margin-left: calc(var(--space-4) * -1);
  margin-right: calc(var(--space-4) * -1);
}
.mxn5 {
  margin-left: calc(var(--space-5) * -1);
  margin-right: calc(var(--space-5) * -1);
}
.mxn6 {
  margin-left: calc(var(--space-6) * -1);
  margin-right: calc(var(--space-6) * -1);
}
.mxn7 {
  margin-left: calc(var(--space-7) * -1);
  margin-right: calc(var(--space-7) * -1);
}
.mxn8 {
  margin-left: calc(var(--space-8) * -1);
  margin-right: calc(var(--space-8) * -1);
}
.mxn9 {
  margin-left: calc(var(--space-9) * -1);
  margin-right: calc(var(--space-9) * -1);
}
.mxn10 {
  margin-left: calc(var(--space-10) * -1);
  margin-right: calc(var(--space-10) * -1);
}
.mxn11 {
  margin-left: calc(var(--space-11) * -1);
  margin-right: calc(var(--space-11) * -1);
}

.myn1 {
  margin-top: calc(var(--space-1) * -1);
  margin-bottom: calc(var(--space-1) * -1);
}
.myn2 {
  margin-top: calc(var(--space-2) * -1);
  margin-bottom: calc(var(--space-2) * -1);
}
.myn3 {
  margin-top: calc(var(--space-3) * -1);
  margin-bottom: calc(var(--space-3) * -1);
}
.myn4 {
  margin-top: calc(var(--space-4) * -1);
  margin-bottom: calc(var(--space-4) * -1);
}
.myn5 {
  margin-top: calc(var(--space-5) * -1);
  margin-bottom: calc(var(--space-5) * -1);
}
.myn6 {
  margin-top: calc(var(--space-6) * -1);
  margin-bottom: calc(var(--space-6) * -1);
}
.myn7 {
  margin-top: calc(var(--space-7) * -1);
  margin-bottom: calc(var(--space-7) * -1);
}
.myn8 {
  margin-top: calc(var(--space-8) * -1);
  margin-bottom: calc(var(--space-8) * -1);
}
.myn9 {
  margin-top: calc(var(--space-9) * -1);
  margin-bottom: calc(var(--space-9) * -1);
}
.myn10 {
  margin-top: calc(var(--space-10) * -1);
  margin-bottom: calc(var(--space-10) * -1);
}
.myn11 {
  margin-top: calc(var(--space-11) * -1);
  margin-bottom: calc(var(--space-11) * -1);
}

.m-auto {
  margin: auto;
}
.mt-auto {
  margin-top: auto;
}
.mr-auto {
  margin-right: auto;
}
.mb-auto {
  margin-bottom: auto;
}
.ml-auto {
  margin-left: auto;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
